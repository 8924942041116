@import url(//db.onlinewebfonts.com/c/b6539b6d3432c623d8d4f9cc2a29589e?family=Berlin+Sans+FB+Demi);
@font-face {
  font-family: 'BerlinSans';
  src: url('./fonts/berlin-sans-fb-demi-bold.ttf'); /* IE9 Compat Modes */
  src: url('./fonts/berlin-sans-fb-demi-bold.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: 'LoversQuarrel';
  src: url('./fonts/LoversQuarrel-Regular.ttf'); /* IE9 Compat Modes */
  src: url('./fonts/LoversQuarrel-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}

html {
  scroll-behavior: smooth;
}


* {
  font-family: "BerlinSans", "Nanum Myeongjo", serif;
}

.App {
  width: 60%;
  margin-right: auto;
  margin-left: auto;

  h1 {
    text-align: center;
    font-family: "Brush Script MT", "Snell Roundhand", cursive;
    font-size: 56px;
    font-style: oblique;
    color: #797e81;
    margin: 0;
    padding-top: 0;
    font-weight: 700;
  }
  
  h2 {
    text-align: center;
    // font-family: "Brush Script MT", "Snell Roundhand", cursive;
    font-size: 36px;
    font-style: oblique;
    color: #797e81;
    // border-bottom: 1px solid #797e81;
    padding-bottom: 18px;
  }
  
  p {
    text-align: justify;
    font-family: "BerlinSans", "Nanum Myeongjo", serif;
    font-size: 24px;
    font-style: oblique;
    line-height: 1.5;
    color: #797e81;
  }

  @media only screen and (max-width: 1000px) {
    p {
      font-size: 1em;
      font-family: "Nanum Myeongjo",serif;
      font-style: oblique;
      line-height: 1.5;
      color: #797e81;
      text-align: justify;
    }
  }
}

@media screen and (max-width: 1000px) {
  .App {
    padding: 1em;
    width: 100%;
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff2d;
  background: #f8f8ff;
  z-index: 2;
  margin-bottom: 10px;
}

.navBar {
  margin-right: auto;
  margin-left: auto;
  text-align: center;

  a {
    text-align: center;
    border: none;
    outline: none;
    color: #797e81;
    text-align: center;
    text-decoration: none;
    // font-family: "Brush Script MT", "Snell Roundhand",cursive;
    font-size: 32px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.4s;
  }

  a:active {
    text-decoration: none;
    color: #797e81!important;
  }

  a:hover {
    color: #C0C0C0;
    transition-duration: 0.4s;
  }
}