#siteIntro {
  width: 100%;
  background-image: url(../../images/pictureBackground.jpg);
  background-size: 100% 100%;

  #firstBlock {
    #name {
      margin-top: 30%;
      

      h1 {
        color: black;
        font-family: 'LoversQuarrel';
        text-align: right;
      }
    }

    #mitra img {
      width: 270px;
      margin-right: auto;
      display: block;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  #tagline {
    h2 {
      color: black;
      font-size: 16pt;
    }
  }

  margin-bottom: 2em;
}

@media only screen and (max-width: 1000px) {
  #siteIntro {
    #firstBlock {
      #name {
        h1 {
          text-align: center;
        }
      }
    }
  }
}