#home {
  background-image: url('../../images/siteImages/featuredArtBG.jpg');
  background-size: 100% 100%;
  background-position: bottom;
  padding: 10px;
  margin-bottom: 2em;
}

#homeContent {
  text-align: center;
  -webkit-column-count: 3;
  -webkit-column-gap:   15px;
  -moz-column-count:    3;
  -moz-column-gap:      15px;
  column-count:         3;
  column-gap:           15px;

  img:nth-child(even) {
    width: 100% !important;
    height: auto !important;
    padding-bottom: 10px;
    display: block;
  }

  img:nth-child(odd) {
    width: 80% !important;
    height: auto !important;
    padding-bottom: 10px;
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }


}

@media (max-width: 1000px) {
  #home {
    background: #f8f8ff;
  }

  #homeContent {
  -moz-column-count:    3;
  -webkit-column-count: 3;
  column-count:         3;
  }
}
@media (max-width: 800px) {
  #home {
    background: #f8f8ff;
  }

  #homeContent {
  -moz-column-count:    2;
  -webkit-column-count: 2;
  column-count:         2;
  }
}
@media (max-width: 600px) {
  #home {
    background: #f8f8ff;
  }

  #homeContent {
  -moz-column-count:    1;
  -webkit-column-count: 1;
  column-count:         1;
  }
}

.featuredArt {
  a {
    color: white !important;
  }
}

#modalImage {
  width: 100%;
  background-image: url(../../images/pictureBackground.jpg);
  
  img {
    padding: 15px;
  }
}

p {
  margin-bottom: .5rem !important;
}