#footer {
  padding: 20px; 
  text-align: center;
  border-top: 1px solid #797e81;
  margin-top: 30px;
  color: #797e81;

  a {
    color: black;
    text-decoration: none;
  }
}
