#paintings {
  background-image: url('../../images/siteImages/featuredArtBG.jpg');
  background-size: 100% 100%;
  margin-bottom: 2em;
  padding-bottom: 5em;
  scroll-margin-top: 12em;

  h2 {
    background-color: #f8f8ff;
    padding: 20px;
  }
}

@media only screen and (max-width: 1500px) {
  #paintings {
    background: #f8f8ff;
    padding: 10px;
    margin-bottom: 2em;
    scroll-margin-top: 27em;
  }
}

#paintingsContent, #featuredPaintings {
  -webkit-column-count: 3;
  -webkit-column-gap:   15px;
  -moz-column-count:    3;
  -moz-column-gap:      15px;
  column-count:         3;
  column-gap:           15px;

  img {
    width: 100% !important;
    height: auto !important;
    padding-bottom: 10px;
  }
}

@media (max-width: 1000px) {
  #paintingsContent, #featuredPaintings {
  -moz-column-count:    3;
  -webkit-column-count: 3;
  column-count:         3;
  }
}
@media (max-width: 800px) {
  #paintingsContent, #featuredPaintings {
  -moz-column-count:    2;
  -webkit-column-count: 2;
  column-count:         2;
  }
}
@media (max-width: 600px) {
  #paintingsContent, #featuredPaintings {
  -moz-column-count:    1;
  -webkit-column-count: 1;
  column-count:         1;
  }
}

#carousel img{
  height: 100%;
  width: auto;
}

#carouselImage {
  height: 90%;
  width: auto;
}

.featuredArt {
  a {
    color: white !important;
  }
}

#paintingsArrow {
  width: 30px;
  font-weight: bold;
}