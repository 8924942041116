#contact {
  background-image: url('../../images/siteImages/featuredArtBG.jpg');
  background-size: 100% 100%;
  margin-bottom: 2em;
  padding-bottom: 25px;

  h2 {
    background-color: #f8f8ff;
    padding: 20px;
  }

  #contactForm {
    margin-right: auto;
    margin-left: auto;
  }

  #alert {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1500px) {
  #contact {
    background: #f8f8ff;
    padding: 10px;
    margin-bottom: 2em;
  }
}

@media (max-width: 800px) {
  #contact {
    #alert {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    }
  }
}