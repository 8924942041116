.header { 
  background: #f8f8ff;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto; 
  border-bottom: 1px solid #797e81;
}

@media (max-width: 1000px) {
  .header { 
    h1 {
      font-size: 24pt !important;
    }
  }
}
