@import url(//db.onlinewebfonts.com/c/b6539b6d3432c623d8d4f9cc2a29589e?family=Berlin+Sans+FB+Demi);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}.anchor{display:block;position:relative;top:-250px;visibility:hidden}

.header{background:#f8f8ff;margin-top:10px;margin-left:auto;margin-right:auto;border-bottom:1px solid #797e81}@media (max-width: 1000px){.header h1{font-size:24pt !important}}

#siteIntro{width:100%;background-image:url(/static/media/pictureBackground.e944cc53.jpg);background-size:100% 100%;margin-bottom:2em}#siteIntro #firstBlock #name{margin-top:30%}#siteIntro #firstBlock #name h1{color:black;font-family:'LoversQuarrel';text-align:right}#siteIntro #firstBlock #mitra img{width:270px;margin-right:auto;display:block;margin-top:15px;margin-bottom:15px}#siteIntro #tagline h2{color:black;font-size:16pt}@media only screen and (max-width: 1000px){#siteIntro #firstBlock #name h1{text-align:center}}

#modalImage{background-image:url(/static/media/pictureBackground.e944cc53.jpg)}#modalImage img{padding:15px}

#home{background-image:url(/static/media/featuredArtBG.ee3c3b76.jpg);background-size:100% 100%;background-position:bottom;padding:10px;margin-bottom:2em}#homeContent{text-align:center;-webkit-column-count:3;-webkit-column-gap:15px;-moz-column-count:3;-moz-column-gap:15px;column-count:3;grid-column-gap:15px;column-gap:15px}#homeContent img:nth-child(even){width:100% !important;height:auto !important;padding-bottom:10px;display:block}#homeContent img:nth-child(odd){width:80% !important;height:auto !important;padding-bottom:10px;display:block;text-align:center;margin-left:auto;margin-right:auto}@media (max-width: 1000px){#home{background:#f8f8ff}#homeContent{-webkit-column-count:3;column-count:3}}@media (max-width: 800px){#home{background:#f8f8ff}#homeContent{-webkit-column-count:2;column-count:2}}@media (max-width: 600px){#home{background:#f8f8ff}#homeContent{-webkit-column-count:1;column-count:1}}.featuredArt a{color:white !important}#modalImage{width:100%;background-image:url(/static/media/pictureBackground.e944cc53.jpg)}#modalImage img{padding:15px}p{margin-bottom:.5rem !important}

#bio{background-image:url(/static/media/aboutTheArtistBG.87d03a5b.jpg);background-size:100% 100%;margin-bottom:2em;scroll-margin-top:12em}#bio h2{background-color:#f8f8ff;padding:20px}img{max-width:75%;margin-right:auto;margin-left:auto;display:block}@media only screen and (max-width: 1500px){#bio{background:#f8f8ff;padding:10px;margin-bottom:2em;scroll-margin-top:27em}}@media only screen and (min-width: 1000px){#mitraPainting{width:75%;margin-left:40px;margin-right:auto;display:block;margin-top:150px}}@media only screen and (min-width: 2000px){#mitraPainting{width:75%;margin-left:-60px;margin-right:auto;margin-top:150px}}

#sketches{margin-bottom:2em;background-image:url(/static/media/featuredArtBG.ee3c3b76.jpg);background-size:100% 100%;padding-bottom:5em;scroll-margin-top:12em}#sketches h2{background-color:#f8f8ff;padding:20px}@media only screen and (max-width: 1500px){#sketches{background:#f8f8ff;padding:10px;margin-bottom:2em;scroll-margin-top:27em}}#sketchesContent{text-align:center;-webkit-column-count:3;-webkit-column-gap:15px;-moz-column-count:3;-moz-column-gap:15px;column-count:3;grid-column-gap:15px;column-gap:15px}#sketchesContent img{width:100% !important;height:auto !important;padding-bottom:10px}@media (max-width: 1000px){#sketchesContent{-webkit-column-count:3;column-count:3}}@media (max-width: 800px){#sketchesContent{-webkit-column-count:2;column-count:2}}@media (max-width: 600px){#sketchesContent{-webkit-column-count:1;column-count:1}}@media (min-width: 1000px){#sketches #mobileLink{display:none}}@media (max-width: 1000px){#sketches #paintingsArrow{display:none}}#carousel img{height:100%;width:auto}#carouselImage{height:90%;width:auto}.featuredArt a{color:white !important}#sketchesArrow{width:30px;font-weight:bold}

#paintings{background-image:url(/static/media/featuredArtBG.ee3c3b76.jpg);background-size:100% 100%;margin-bottom:2em;padding-bottom:5em;scroll-margin-top:12em}#paintings h2{background-color:#f8f8ff;padding:20px}@media only screen and (max-width: 1500px){#paintings{background:#f8f8ff;padding:10px;margin-bottom:2em;scroll-margin-top:27em}}#paintingsContent,#featuredPaintings{-webkit-column-count:3;-webkit-column-gap:15px;-moz-column-count:3;-moz-column-gap:15px;column-count:3;grid-column-gap:15px;column-gap:15px}#paintingsContent img,#featuredPaintings img{width:100% !important;height:auto !important;padding-bottom:10px}@media (max-width: 1000px){#paintingsContent,#featuredPaintings{-webkit-column-count:3;column-count:3}}@media (max-width: 800px){#paintingsContent,#featuredPaintings{-webkit-column-count:2;column-count:2}}@media (max-width: 600px){#paintingsContent,#featuredPaintings{-webkit-column-count:1;column-count:1}}#carousel img{height:100%;width:auto}#carouselImage{height:90%;width:auto}.featuredArt a{color:white !important}#paintingsArrow{width:30px;font-weight:bold}

#contact{background-image:url(/static/media/featuredArtBG.ee3c3b76.jpg);background-size:100% 100%;margin-bottom:2em;padding-bottom:25px}#contact h2{background-color:#f8f8ff;padding:20px}#contact #contactForm{margin-right:auto;margin-left:auto}#contact #alert{width:50%;margin-left:auto;margin-right:auto}@media only screen and (max-width: 1500px){#contact{background:#f8f8ff;padding:10px;margin-bottom:2em}}@media (max-width: 800px){#contact #alert{width:75%;margin-left:auto;margin-right:auto}}

#footer{padding:20px;text-align:center;border-top:1px solid #797e81;margin-top:30px;color:#797e81}#footer a{color:black;text-decoration:none}

@font-face{font-family:'BerlinSans';src:url(/static/media/berlin-sans-fb-demi-bold.d1459834.ttf);src:url(/static/media/berlin-sans-fb-demi-bold.d1459834.ttf) format("truetype")}@font-face{font-family:'LoversQuarrel';src:url(/static/media/LoversQuarrel-Regular.dc23ea5a.ttf);src:url(/static/media/LoversQuarrel-Regular.dc23ea5a.ttf) format("truetype")}html{scroll-behavior:smooth}*{font-family:"BerlinSans", "Nanum Myeongjo", serif}.App{width:60%;margin-right:auto;margin-left:auto}.App h1{text-align:center;font-family:"Brush Script MT", "Snell Roundhand", cursive;font-size:56px;font-style:oblique;color:#797e81;margin:0;padding-top:0;font-weight:700}.App h2{text-align:center;font-size:36px;font-style:oblique;color:#797e81;padding-bottom:18px}.App p{text-align:justify;font-family:"BerlinSans", "Nanum Myeongjo", serif;font-size:24px;font-style:oblique;line-height:1.5;color:#797e81}@media only screen and (max-width: 1000px){.App p{font-size:1em;font-family:"Nanum Myeongjo",serif;font-style:oblique;line-height:1.5;color:#797e81;text-align:justify}}@media screen and (max-width: 1000px){.App{padding:1em;width:100%}}.sticky{position:-webkit-sticky;position:sticky;top:0;padding-bottom:10px;border-bottom:1px solid #ffffff2d;background:#f8f8ff;z-index:2;margin-bottom:10px}.navBar{margin-right:auto;margin-left:auto;text-align:center}.navBar a{text-align:center;border:none;outline:none;color:#797e81;text-align:center;text-decoration:none;font-size:32px;margin:4px 2px;cursor:pointer;transition-duration:0.4s}.navBar a:active{text-decoration:none;color:#797e81 !important}.navBar a:hover{color:#C0C0C0;transition-duration:0.4s}

